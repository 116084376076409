<template>
    <a :href="article.url" target="_blank" rel="follow">
        <article class="store-card-article">
            <picture class="store-card-article_image">
                <img :src="article.image.url" :alt="article.title" />
            </picture>
            <div class="store-card-article_info">
                <h3 class="heading-3">{{ article.title }}</h3>
                <p>
                    Artículo en: <span class="font-medium">{{ article.published_in }}</span>
                </p>
                <footer>
                    <p>
                        Publicado por: <span class="font-medium">{{ article.author }}</span>
                    </p>
                </footer>
            </div>
        </article>
    </a>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import { format, parseISO } from 'date-fns'

import { es as locale } from 'date-fns/locale'

import type { Models } from '~/types/models'

export default defineComponent({
    props: {
        article: {
            type: Object as PropType<Models.ArticleModel>,
            required: true,
        },
    },
    computed: {
        date(): string {
            return format(parseISO(this.article.created_at), "dd 'de' MMMM 'del' yyyy", { locale })
        },
    },
})
</script>

<style lang="postcss" scoped>
.store-card-article {
    @apply overflow-hidden rounded-xl border border-gray-200 bg-white xs:flex;
    &_image {
        @apply block h-32 w-full xs:h-auto xs:w-32 xs:flex-none;
        img {
            @apply h-full w-full object-cover;
        }
    }
    &_info {
        @apply space-y-2 p-4;
        .heading-3 {
            @apply line-clamp-2 text-base font-semibold lg:text-lg;
        }
        div {
            @apply line-clamp-3;
        }
    }
}
</style>

<script setup lang="ts">
import type { Api } from '~~/global'

import { replacePlaceholders } from '~/util/textFunction'
import { useFallbackStore } from '~/store/fallback'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { $lang } = useNuxtApp()
const { setStoresFallback, storesListFallback } = useFallbackStore()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: info } = await useAsyncData('stores-list-index', async () => {
    if (storesListFallback) {
        return storesListFallback
    } else {
        const result = await $fetch<Api.Responses.Pages.Stores | Api.Responses.ResourceNotFound>(
            endpoints.pages.stores.path,
            {
                method: 'GET',
                headers: buildHeaders(),
                baseURL,
            },
        ).catch((e) => e.data || false)
        return result
    }
})
if (!info.value || info.value?.error) {
    throw createError({
        statusCode: 500,
        message: $lang.views.storesBridge.error,
    })
}
if (info.value.feedback === 'resource_not_found') {
    throw createError({
        statusCode: 404,
        message: $lang.views.storesBridge.store_not_found,
    })
}

const data = info.value.page
setStoresFallback(info.value)
const Route = useRoute()

const currentDates = currentDate()

const letterTitle = `Categorías con :letter | ${$lang.brand}`
const letterDesc = `Descubre los mejores cupones y descuentos en categorías que comienzan con :letter en ${$lang.brand}. ¡Ahorra con estilo!`

const {
    public: { origin, siteName },
} = useRuntimeConfig()

const placeholder: [string, string][] = !Route.params.slug
    ? [
          [':month', currentDates.month || ''],
          [':day', String(currentDates.day) || ''],
          [':year', String(currentDates.year) || ''],
          [':site', siteName || ''],
      ]
    : [[':letter', ((Route.params.slug as string) || '').toUpperCase()]]

const [title, description] = replacePlaceholders(placeholder, [
    Route.params.slug ? letterTitle : data.title || '',
    Route.params.slug ? letterDesc : data.description || '',
])

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

/*

 mounted() {
        setTimeout(() => {
            this.$nextTick(() => {
                if (this.info?.page?.banners) this.$store.dispatch('assignBanners', this.info.page.banners)
            })
        }, 2000)
    },

*/
</script>

<template>
    <div class="stores-list-index">
        <div class="stores-list-index__content">
            <h1>{{ $lang.views.storesList.stores }}</h1>
            <div class="stores-list-index__content-tags">
                <ul class="stores-list-index__content-list">
                    <li v-for="(category, index) in data.data.featured_stores" :key="index">
                        <LayoutChip :info="category" :path="`/${$lang.routes.brands}/`" />
                    </li>
                </ul>
            </div>
            <div class="stores-list-index__content-filter">
                <LayoutAlphabeticFilter :info="data.data.alphabet_stores" :path="`/${$lang.routes.brands}`" />
            </div>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.stores-list-index {
    &__content {
        @apply space-y-2;
        h1 {
            @apply container mt-6 text-2xl font-semibold text-gray-900;
        }
        &-tags {
            @apply pb-2 md:pb-4;
        }
        &-list {
            @apply container grid grid-cols-2 gap-2 px-2 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6;
        }
        &-filter {
            @apply container pb-4 pt-1 md:pb-8 md:pt-5;
        }
    }
}
</style>
